import moment from 'moment';

const monthConverter = new Map<number,string>([
  [0, 'January'], [1, 'February'], [2, 'March'],
  [3, 'April'], [4, 'May'], [5, 'June'],
  [6, 'July'], [7, 'August'], [8, 'September'],
  [9, 'October'], [10, 'November'], [11, 'December']
]);

export const isLocalToUTCDate = (date: Date | null, isLocaltime: boolean): Date => {
    if(date !== null) {
        if(!isLocaltime) {
            const timeOffset = new Date().getTimezoneOffset();
            date.setMinutes(date.getMinutes() - timeOffset);
        }
    }
    else {
        date = new Date();
    }
    return date;
}

export const DisplayDateString = (date: Date | null, stringFormat: string, isLocalTime: boolean = false, addTimezoneAbbreviation: boolean = true): string => {
    let datetimeString = '';
    if(date !== null) {
        if(!isLocalTime) {
            const timeOffset = new Date().getTimezoneOffset();
            date.setMinutes(date.getMinutes() - timeOffset);
        }
        if(addTimezoneAbbreviation) {
            datetimeString = moment(date).format(stringFormat) + ' ' + new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
        }
        else {
            datetimeString = moment(date).format(stringFormat);
        }
    }
    return datetimeString;
}
