import React from "react";
import ReactDOM from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, themes, Notification } from 'hcss-components';
import { BrowserRouter } from "react-router-dom";
import { initializeTelemetry } from "./telemetry";

ReactDOM.render(
  <BrowserRouter>
    <Notification />
    <ThemeProvider theme={themes.heavyBid}>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

initializeTelemetry();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
