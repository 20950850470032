import "../Dashboard.css";
import React, { useState, useEffect, useRef } from "react";
import ReactTooltip from 'react-tooltip';
import { Formik, Form, ErrorMessage } from 'formik';
import { PageHeader, Modal, Button, Icon, Select, Textarea, TextField, Checkbox, TreeSelector, Link, LoadingContainer, notify} from 'hcss-components';
import Grid from '@material-ui/core/Grid';
import { DisplayDateString, isLocalToUTCDate } from '../../../DTO/Constants/Function/DateConversion';
import { useUserPost } from '../../../DTO/Constants/Function/CustomHooks/useUserPost';
import { ReturnIsAllLoaded } from '../../../DTO/Constants/Function/ReturnIsAllLoaded';

import { getProductGroupAsync, getStatusIconsAsync, getIncidentStatusAsync, getIncidentAsync } from '../../../API/getAPI';
import { postIncidentAsync } from '../../../API/postAPI';
import { putIncidentAsync } from '../../../API/putAPI';
import { ProductGroupDTO } from '../../../DTO/ProductGroupDTO';
import { ProductDTO } from '../../../DTO/ProductDTO';
import { StatusLabelDTO } from '../../../DTO/StatusLabelDTO';
import { IncidentDTO } from '../../../DTO/IncidentDTO';
import { IncidentStatusDTO } from '../../../DTO/IncidentStatusDTO';
import { IncidentUpdateDTO } from '../../../DTO/IncidentUpdateDTO';
import { IncidentLabelNames, StatusLabelNames } from '../../../DTO/Constants/Object/ConstantsList';
import { UserDetails, IsUserAdmin } from "../../../Auth/UserAuth";
import { useAuth } from "oidc-react";

import { useParams, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { GetEnvironmentConfig } from "../../../App.Config";

export const IncidentForm = ():JSX.Element => {
  // isLoaded is set to true whenever all API calls have finished. On state change page is refreshed loading all the new information.
  const isAllLoaded = useRef<boolean[]>([false, false, false]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  
  // Objects holding the products (HeavyJob Web, etc...), incidents status (investigating, identified, resolved)
  // and status labels (Good, warning, critical, information)
  const [productSelection, setProductSelection] = useState<ProductGroupDTO[]>([]);
  const [incidentSelection, setIncidentSelection] = useState<IncidentStatusDTO[]>([]);
  const [statusSelection, setStatusSelection] = useState<StatusLabelDTO[]>([]);
  
  // Id specifies if the incident form is edit or add. If id === undefined, then it is Add Incident because and incident Id
  // does not exist. Id comes from url-parameter.
  const { id } = useParams<{id: string}>();
  const [incident, setIncident] = useState<IncidentDTO | null>(null);
  const [incidentStatusInput, setIncidentStatusInput] = useState<{label: string, value: string}>();
  const [isLocalTime, setIsLocalTime] = useState<boolean[]>([false, false, false]);
  const [isResolved, setIsResolved] = useState<boolean>(false);

  const pageHistory = useHistory();
  const isUserAdmin = useRef<boolean>(IsUserAdmin());

  interface DetailedIncidentUpdateInterface {
    id: string;
    details: string;
    time: Date;
    isAdded: boolean;
  }

  const user = UserDetails();
  const auth = useAuth();

  /* isProductSelected is a two-dimension array containing an array of product objects. The product objects contain the attributes
    {name: (product's name), status: (product's status label), isSelected: (does the product belong to the incident)}.
    The logic is, isProductSelected is a two-dimensional array, isProductSelected[firstIndex] is a product group (HeavyJob) and
    isProductSelected[firstIndex][secondIndex] is a product inside a product group (Heavy Job - Web). This structure helps to access
    any product object by using a map function, by first mapping isProductSelected to iterate through the firstIndex and nesting
    another map inside a individual productGroup through using the secondIndex. 
      
    Visual:
    isProductSelected:
    [
      HeavyBid:
      [{name: HeavyBid Desktop, status: '', isSelected: false}, {name: HeavyBid PreConstruction, status: 'Warning', isSelected: true}, etc...]

      HeavyJob:
      [{name: HeavyJob Desktop, status: '', isSelected: false}, {name: HeavyJob Web, status: '', isSelected: false}, etc...]
      
      etc... (For every ProductGroup containing a list of products)
    ]
  */
  interface ProductSelectedInterface {
    name: string,
    status: string,
    isSelected: boolean
  }
  const [isProductSelected, setIsProductSelected] = useState<ProductSelectedInterface[][]>([]);
  const [isProductGroupSelected, setIsProductGroupSelected] = useState<boolean[]>([]);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const [isProductGroupsHidden, setIsProductGroupsHidden] = useState<boolean>(false);
  const handleProductGroupsDisplay = () => {
    setIsProductGroupsHidden(previous => !previous);
  }
  const [isProductGroupHidden, setIsProductGroupHidden] = useState<boolean[]>([])
  const isAdmin = IsUserAdmin();

  const LoadIncidentFormInformation = (isMounted: boolean, isEditIncident: boolean) => {
    async function EditIncident() {
      const isProductSelectedMap = new Map<string, ProductSelectedInterface>();
      await getIncidentAsync(id).then(resolution => {
        if(isMounted) {
          setIncident(resolution.data);
          setIncidentStatusInput({label: resolution.data.incidentStatus.name, value: resolution.data.incidentStatus.name});

          const incidentUpdateTemp: DetailedIncidentUpdateInterface[] = resolution.data.incidentUpdates.map((incident: IncidentUpdateDTO) => {
            const updateTime = new Date(incident.time);
            updateTime.setMinutes(updateTime.getMinutes() - new Date().getTimezoneOffset());
            return {id: incident.id, details: incident.details, time: updateTime};
          });
          setDetailedIncidentUpdate([...incidentUpdateTemp]);

          resolution.data.products.map((product: ProductDTO) => {
            isProductSelectedMap.set(product.name, {name: product.name, status: product.statusLabel.name, isSelected: true});
            originalProducts.current.push({id: product.id, name: product.name, statusLabel: {id: product.statusLabel.id}});
          });
        }
      });

      await getProductGroupAsync().then(resolution => {
        if(isMounted) {
          setProductSelection([...resolution.data]);
          let isAllProductsSelected: boolean = true;
          resolution.data.filter((incident: IncidentDTO) => {
            if(isAdmin) {
              return true;
            }
            else {
              return !incident.isTestingIncident;
            }
          })
          .map((productGroup: ProductGroupDTO) => {
            let isProductGroupSelectedTemp: boolean = true;
            let isSingleProductSelected: boolean = false;
            const tempProductGroup: ProductSelectedInterface[] = productGroup.products.map((product: ProductDTO) => {
              const returnProduct: ProductSelectedInterface | undefined = isProductSelectedMap.get(product.name);
              if(returnProduct && returnProduct.name && returnProduct.status) {
                isSingleProductSelected = true;
                return {name: returnProduct.name, status: returnProduct.status, isSelected: true};
              } 
              else {
                isAllProductsSelected = false;
                isProductGroupSelectedTemp = false;
                return {name: product.name, status: '', isSelected: false};
              }
            });

            setIsProductSelected((previous) => {
              return [...previous, tempProductGroup];
            });
            setIsProductGroupSelected((previous) => {
              return [...previous, isProductGroupSelectedTemp];
            });

            setIsProductGroupHidden((previous) => {
              return [...previous, !isSingleProductSelected];
            });
          });
          setIsAllSelected(isAllProductsSelected);
        }
      });
      isAllLoaded.current[0] = true;
      setIsLoaded(ReturnIsAllLoaded(isAllLoaded.current));
    }

    async function AddIncident() {
      await getProductGroupAsync().then(resolution => {
        if(isMounted) {
          setProductSelection([...resolution.data]);
          resolution.data.map((productGroup: ProductGroupDTO) => {
            setIsProductGroupHidden(previous => {
              return [...previous, true];
            });
            
            setIsProductSelected((previous) => {
              return [...previous, [...productGroup.products.map((product: ProductDTO) => {
                return ({name: product.name, status: '', isSelected: false});
              })]];
            });

            setIsProductGroupSelected((previous) => {
              return [...previous, false];
            });
          });
        }
      });
      isAllLoaded.current[0] = true;
      setIsLoaded(ReturnIsAllLoaded(isAllLoaded.current));
    }

    isEditIncident ? EditIncident() : AddIncident();
    
    async function getIncidentStatus() {
      await getIncidentStatusAsync().then(resolution => {
        if(isMounted) {
          setIncidentSelection([...resolution.data]);
        }
      });
      isAllLoaded.current[1] = true;
      setIsLoaded(ReturnIsAllLoaded(isAllLoaded.current));
    }
    getIncidentStatus();

    async function getStatusIcons() {
      await getStatusIconsAsync().then(resolution => {
        if(isMounted) {
          setStatusSelection([...resolution.data]);
        }
      });
      isAllLoaded.current[2] = true;
      setIsLoaded(ReturnIsAllLoaded(isAllLoaded.current));
    }
    getStatusIcons();
  }
  
  // originalProducts is compared with updatedProducts on submission of form. If a product inside originalProducts
  // is not inside updatedProducts, it sets the product's isDeleted flag to true, removing the product from the incident
  const originalProducts = useRef<{id: string, name: string, statusLabel: {id: string}}[]>([]);
  useEffect(() => {
    let isMounted = true;
    
    if(id !== undefined) {
      LoadIncidentFormInformation(isMounted, true);
    }
    else {
      setIsLocalTime([true, true, true]);
      LoadIncidentFormInformation(isMounted, false);
    }

    return () => {isMounted = false};
  }, []);

  const handleIncidentStatus = (selectedOption: any) => {
    setIncidentStatusInput(selectedOption);
  }

  const handleDetailedIncidentUpdate = (incidentUpdateString: string) => {
    if(incidentUpdateString !== null) {
      (document.getElementById('detailedIncidentUpdate') as HTMLInputElement)!.value = '';
      if(incidentUpdateString.trim()) {
        setDetailedIncidentUpdate((previous) => {
          return [...detailedIncidentUpdate, {id: uuidv4(), details: incidentUpdateString, time: new Date(), isAdded: true}];
        });
      }
    }
  }

  const validateHandler = (values: any):Partial<Error> => {
    interface Error {
      [key: string]: any
    }
    const errors: Partial<Error> = {};

    let atleastOneProduct: boolean = false;
    const productsWithoutStatus: string[] = [];
    isProductSelected.map((productGroup: ProductSelectedInterface[], firstIndex: number) => {
      productGroup.map((product: ProductSelectedInterface, secondIndex: number) => {
        if(product.isSelected) {
          atleastOneProduct = true;
        }
        if(product.isSelected && !product.status) {
          productsWithoutStatus.push(product.name);
        }
      });
    });
    if(!atleastOneProduct && incidentStatusInput?.label !== IncidentLabelNames.resolvedString) {
      errors.productsSelected = "Product(s) must be selected.";
    }

    if(!values.title) {
      errors.title = 'Enter a Title for the incident.';
    } else if (!values.title.trim()) {
      errors.title = 'Enter a Title for the incident.';
    }

    if(!values.incidentStatus) {
      errors.incidentStatus = 'Indicate the Incident Status.';
    }

    if (productsWithoutStatus.length) {
        errors.productStatus = `Select status for Product(s): ${productsWithoutStatus.join(', ')}`;
    }

    if(!values.incidentDescription) {
      errors.incidentDescription = 'Enter a Description for the incident.';
    } else if (!values.incidentDescription.trim()) {
      errors.incidentDescription = 'Enter a Description for the incident.';
    }

    if(!values.startTime) {
      errors.startTime = 'Enter the Start Time of the incident.';
    }

    if(values.startTime && values.resolveTime) {
      const resolveTime = new Date(values.resolveTime);
      resolveTime.setMinutes(resolveTime.getMinutes() + new Date().getTimezoneOffset());

      if(new Date(resolveTime).getTime() < new Date(values.startTime).getTime()) {
        errors.resolveTime = 'Incident cannot be Resolve Time cannot be before Start Time.'
      }
    }

    if(values.startTime && values.expectedResolveTime) {
      const expectedResolveTime = new Date(values.expectedResolveTime);
      expectedResolveTime.setMinutes(expectedResolveTime.getMinutes() + new Date().getTimezoneOffset());
      
      if(new Date(expectedResolveTime).getTime() < new Date(values.startTime).getTime()) {
        errors.expectedResolveTime = 'Expected Recovery Time cannot be before Start Time.'
      }
    }

    if(incidentStatusInput?.label === IncidentLabelNames.resolvedString) {
      if(!values.resolveTime) {
        errors.resolveTime = 'Resolve Time cannot be empty on a "Resolved" status.'
      }
    }
    
    const missingValues = Object.values(errors);
    if(missingValues.length) {
      let dangerMessage = <>{missingValues.map((errorMessage: string, index: number) => <div key={index}>{errorMessage}<br/></div>)}</>;
      notify("danger","Missing fields",dangerMessage,3500);
    }
    return errors;
  }

  const onSubmitHandler = async (values: any) => {
    const UserObject: {id: string, name: string, email: string, phoneNumber: string, isAdmin: boolean} = {
      id: user.userId,
      name: user.givenName + ' ' + user.surName ?? 'No Name',
      email: user.email ?? 'No Email',
      phoneNumber: '000-000-0000',
      isAdmin: isUserAdmin.current
    };

    const ProductId = new Map();
    productSelection.map((productGroup: ProductGroupDTO) => {
      productGroup.products.map((product: ProductDTO) => {
        ProductId.set(product.name, product.id);
      })
    });

    const IncidentId = new Map();
    incidentSelection.map((incident: IncidentStatusDTO) => {
      IncidentId.set(incident.name, incident.id);
    });
    
    const StatusId = new Map();
    statusSelection.map((status: StatusLabelDTO) => {
      StatusId.set(status.name, status.id);
    });

    const updatedProducts = new Map<string, {id: string, statusLabel: {id: string}, isDeleted: boolean}>();
    isProductSelected.map((productGroup: ProductSelectedInterface[], firstIndex: number) => {
      productGroup.map((product: ProductSelectedInterface, secondIndex: number) => {
        if(product.isSelected) {
          updatedProducts.set(product.name, {id: ProductId.get(product.name), statusLabel: {id: incidentStatusInput?.label === IncidentLabelNames.resolvedString ? StatusId.get('Good') : StatusId.get(product.status)}, isDeleted: false});
        }
      });
    });

    originalProducts.current.map((originalProduct: {id: string, name: string, statusLabel: {id: string}}) => {
      if(updatedProducts.get(originalProduct.name) === undefined) {
        updatedProducts.set(originalProduct.name, {id: originalProduct.id, statusLabel: {id: originalProduct.statusLabel.id}, isDeleted: true});
      }
    });
    const finalProducts: {id: string, statusLabel: {id: string}, isDeleted: boolean}[] = Array.from(updatedProducts.values());

    let startTime: Date | null = null;
    let resolveTime: Date | null = null;
    let expectedResolveTime: Date | null = null;
    
    // Adding Incident (Time is auto-formatted to String, Localtime)
    if(id === undefined) {
      if(values.startTime) {
        startTime = isLocalToUTCDate(new Date(values.startTime), isLocalTime[0]);
      }
      if(values.resolveTime) {
        resolveTime = isLocalToUTCDate(new Date(values.resolveTime), isLocalTime[1]);
      }
      if(values.expectedResolveTime) {
        expectedResolveTime = isLocalToUTCDate(new Date(values.expectedResolveTime), isLocalTime[2]);
      }
    }
    // Editing Incident (Time is auto-formatted to Date, UTC on default. String, Local on input)
    else {
      if(values.startTime) {
        if(isLocalTime[0]) {
          startTime = isLocalToUTCDate(new Date(values.startTime), isLocalTime[0]);
        }
        else {
          startTime = isLocalToUTCDate(values.startTime, isLocalTime[0]);
        }
      }
      if(values.resolveTime) {
        if(isLocalTime[1]) {
          resolveTime = isLocalToUTCDate(new Date(values.resolveTime), isLocalTime[1]);
        }
        else {
          resolveTime = isLocalToUTCDate(values.resolveTime, isLocalTime[1]);
        }

        if(incidentStatusInput?.label !== IncidentLabelNames.resolvedString) {
          resolveTime = null;
        }
      }
      if(values.expectedResolveTime) {
        if(isLocalTime[2]) {
          expectedResolveTime = isLocalToUTCDate(new Date(values.expectedResolveTime), isLocalTime[2]);
        }
        else {
          expectedResolveTime = isLocalToUTCDate(values.expectedResolveTime, isLocalTime[2]);
        }
      }
    }

    const isActiveIncident: boolean = incidentStatusInput?.label === IncidentLabelNames.resolvedString ? false : true
    
    const incidentDTO = {
      id: (id !== undefined ? id : uuidv4()),
      title: values.title,
      description: values.incidentDescription,
      startTime: startTime,
      resolveTime: resolveTime,
      expectedResolveTime: expectedResolveTime,
      isActiveIncident: isActiveIncident,
      isDeleted: false,
      incidentStatus: {
        id: IncidentId.get(incidentStatusInput?.label)
      },
      products: [...finalProducts],
      user: {...UserObject},
      incidentUpdates: detailedIncidentUpdate.map((update: DetailedIncidentUpdateInterface) => {
        if(update.isAdded) {
          const updatedTime = isLocalToUTCDate(update.time, true);
          update.time = (updatedTime !== null ? updatedTime : update.time);
        }
        return(
          {
            id: update.id,
            details: update.details,
            time: update.time,
            incidentStatus: {
              id: IncidentId.get(incidentStatusInput?.label),
              name: incidentStatusInput?.label
            },
            user: {...UserObject}
          }
        );
      })
    }

    if(UserObject.isAdmin) {
      if(id !== undefined) {
        await putIncidentAsync(incidentDTO);
      } 
      else {
        await postIncidentAsync(incidentDTO);
      }
    }
    pageHistory.push('/');
  }

  // If id !== undefined, then the form is an Edit Incident.
  // Nested-conditionals check if the DOM-object (document.getElementById) has loaded.
  // Other conditionals check if the value (incident and incident.startTime or incident.expectedResolveTime) are not null or undefined (typescript)
  if(id !== undefined) {
    const timeOffset = new Date().getTimezoneOffset();
    if(!isLocalTime[0] && document.getElementById(`startTime`) && incident && incident.startTime) {
      const startTime = new Date(incident.startTime);
      (document.getElementById(`startTime`) as HTMLInputElement)!.value = DisplayDateString(startTime, '', false, false).substring(0,16);
    }
    if(!isLocalTime[2] && document.getElementById(`expectedResolveTime`) && incident && incident.expectedResolveTime) {
      const expectedResolveTime = new Date(incident.expectedResolveTime);
      (document.getElementById(`expectedResolveTime`) as HTMLInputElement)!.value = DisplayDateString(expectedResolveTime, '', false, false).substring(0,16);
    }
  }

  interface InitialValuesInterface {
    productsAffected: ProductDTO[] | null;
    title: string | null;
    incidentStatus: IncidentStatusDTO | null;
    productStatus: ProductDTO[] | null;
    incidentDescription: string | null;
    detailedIncidentUpdate: IncidentUpdateDTO[] | null;
    startTime: Date | null;
    resolveTime: Date | null;
    expectedResolveTime: Date | null;
  }
  const initialValues: InitialValuesInterface = {
    productsAffected: null,
    title: null,
    incidentStatus: null,
    productStatus: null,
    incidentDescription: null,
    detailedIncidentUpdate: null,
    startTime: null,
    resolveTime: null,
    expectedResolveTime: null
  };
  if(incident !== null) {
    initialValues.productsAffected = incident.products;
    initialValues.title = incident.title;
    initialValues.incidentStatus = incident.incidentStatus;
    initialValues.productStatus = incident.products;
    initialValues.incidentDescription = incident.description;
    if(incident.incidentUpdates !== undefined) {
      initialValues.detailedIncidentUpdate = incident.incidentUpdates;
    }
    initialValues.startTime = new Date(incident.startTime);
    if(incident.resolveTime !== undefined) {
      if(incident.resolveTime !== null) {
        initialValues.resolveTime = new Date(incident.resolveTime);
      }
    }
    if(incident.expectedResolveTime !== undefined) {
      if(incident.expectedResolveTime !== null) {
        initialValues.expectedResolveTime = new Date(incident.expectedResolveTime);
      }
    }
  }

  const handleProductGroupDisplay = (firstIndex: number) => {
    setIsProductGroupHidden(previous => {
      previous[firstIndex] = !previous[firstIndex];
      return [...previous];
    })
  }

  const handleProductStatus = (event: React.ChangeEvent<HTMLInputElement>, firstIndex: number, secondIndex: number) => {
    const productStatus: string = event.currentTarget.value;
    setIsProductSelected(previous => {
      previous[firstIndex][secondIndex].status = productStatus;
      return [...previous];
    });
  }

  const handleProductsAffected = (firstIndex: number, secondIndex: number) => {
    const updateStatus = !isProductSelected[firstIndex][secondIndex].isSelected;
    if(!updateStatus) {
      setIsAllSelected(previous => {
        return updateStatus;
      });
      setIsProductGroupSelected(previous => {
        previous[firstIndex] = updateStatus;
        return [...previous];
      });
    }
    setIsProductSelected(previous => {
      previous[firstIndex][secondIndex].isSelected = updateStatus;
      if(!updateStatus) {
        previous[firstIndex][secondIndex].status = '';
      }
      return [...previous];
    });
  }
  const handleProductGroupsAffected = (firstIndex: number) => {
    const updateStatus = !isProductGroupSelected[firstIndex];
    if(!updateStatus) {
      setIsAllSelected(previous => {
        return updateStatus;
      });
    }
    setIsProductGroupSelected(previous => {
      previous[firstIndex] = updateStatus;
      return [...previous];
    });
    setIsProductSelected(previous => {
      previous[firstIndex].map((previousGroup: ProductSelectedInterface, secondIndex: number) => {
        previous[firstIndex][secondIndex].isSelected = updateStatus;
      })
      return [...previous];
    });
  }

  const handleAllAffected = () => {
    const updateStatus = !isAllSelected;
    setIsAllSelected(previous => {
      return updateStatus
    });
    setIsProductGroupSelected(previous => {
      previous.map((groupStatus: boolean, firstIndex: number) => {
        previous[firstIndex] = updateStatus;
      });
      return [...previous];
    });
    setIsProductSelected(previous => {
      previous.map((productGroup: ProductSelectedInterface[], firstIndex: number) => {
        productGroup.map((product: ProductSelectedInterface, secondIndex: number) => {
          previous[firstIndex][secondIndex].isSelected = updateStatus;
        });
      });
      return [...previous];
    });
  }

  const handleAllCheckbox = ():boolean => {
    isProductSelected.map((productGroup: ProductSelectedInterface[]) => {
      productGroup.map((product: ProductSelectedInterface) => {
        if(!product.isSelected) {
          return false;
        }
      })
    })
    return true;
  }
  const handleProductGroupCheckbox = (firstIndex: number):boolean => {
    if(firstIndex < isProductSelected.length) {
      isProductSelected[firstIndex].map((product: ProductSelectedInterface) => {
        if(!product.isSelected) {
          return false;
        }
      });
      return true;
    }
    return false;
  }
  const handleProductCheckbox = (firstIndex: number, secondIndex: number):boolean => {
    if(firstIndex < isProductSelected.length) {
      if(secondIndex < isProductSelected[firstIndex].length) {
        if(isProductSelected[firstIndex][secondIndex].isSelected) {
          return true;
        }
      }
    }
    return false;
  }

  const changeToLocalTime = (index: number) => {
    setIsLocalTime(previous => {
      previous[index] = true;
      return [...previous];
    });
  }

  // Maps the objects inside productSelection into an array of JSX.Element.
  const productsDisplay: JSX.Element[] = productSelection.map((productGroup: ProductGroupDTO, firstIndex: number) => {
    return(
      <div key={`${firstIndex}`} className='fit-content' style={{marginLeft: '19.5px', minWidth: '320px'}}>
        <Icon name={isProductGroupHidden[firstIndex] ? 'plus' : 'minus'} onClick={() => handleProductGroupDisplay(firstIndex)} margin='both'></Icon>
        <Checkbox onChange={() => handleProductGroupsAffected(firstIndex)} checked={firstIndex < isProductGroupSelected.length ? isProductGroupSelected[firstIndex] : false}></Checkbox>
        {productGroup.name}
        <div style={{display: isProductGroupHidden[firstIndex] ? 'none' : 'block', marginLeft: '40.5px'}}>
          {productGroup.products.map((product: ProductDTO, secondIndex: number) => {
            return(
              <div key={`${firstIndex}.${secondIndex}`} className='fit-content'>
                <Checkbox onChange={() => handleProductsAffected(firstIndex, secondIndex)} checked={handleProductCheckbox(firstIndex, secondIndex)}>{product.name}</Checkbox>
              </div>
            );
          })}
        </div>
      </div>
    );
  });

  const statusTableDisplay: JSX.Element[] = statusSelection.map((status: StatusLabelDTO, index: number) => {
    return(
      <td key={index}>
        <div>
          {status.name}
          <Icon name={status.iconName} style={{color: status.iconColor}} margin='both'></Icon>
        </div>
      </td>
    );
  });

  let statusCheckboxDisplay: JSX.Element[] = [];
  isProductSelected.map((productGroup: ProductSelectedInterface[], firstIndex: number) => {
    productGroup.map((product: ProductSelectedInterface, secondIndex: number) => {
      statusCheckboxDisplay.push(
        <tr key={`${firstIndex}.${secondIndex}`} style={{display: isProductSelected[firstIndex][secondIndex].isSelected ? 'table-row' : 'none'}}>
          <td><div style={{marginLeft: '15px'}}>{product.name}</div></td>
          <td>
            <label><input type='radio' value='Good' checked={isProductSelected[firstIndex][secondIndex].status === StatusLabelNames.good ? true : false} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleProductStatus(event, firstIndex, secondIndex)}/></label>
          </td>
          <td>
            <label><input type='radio' value='Information' checked={isProductSelected[firstIndex][secondIndex].status === StatusLabelNames.information ? true : false} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleProductStatus(event, firstIndex, secondIndex)}/></label>
          </td>
          <td>
            <label><input type='radio' value='Warning' checked={isProductSelected[firstIndex][secondIndex].status === StatusLabelNames.warning ? true : false}onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleProductStatus(event, firstIndex, secondIndex)}/></label>
          </td>
          <td>
            <label><input type='radio' value='Critical' checked={isProductSelected[firstIndex][secondIndex].status === StatusLabelNames.critical ? true : false} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleProductStatus(event, firstIndex, secondIndex)}/></label>
          </td>
        </tr>
      );
    })
  });

  const incidentStatusDisplay: {label: string, value: string}[] = incidentSelection.map((incident: IncidentStatusDTO) => {
    return({label: incident.name, value: incident.name});
  })

  const [detailedIncidentUpdate, setDetailedIncidentUpdate] = useState<DetailedIncidentUpdateInterface[]>([]);
  const IncidentTable = ():JSX.Element => {
    return(
      <table className='detailed-incident-table' style={{display: detailedIncidentUpdate.length ? 'table' : 'none'}}>
        <tbody>
          <tr style={{backgroundColor: 'rgb(221,221,221)'}}>
            <th><b>Detailed Incident Update</b></th>
            <th><b>Time</b></th>
          </tr>
          {detailedIncidentUpdate.map((tableRow: DetailedIncidentUpdateInterface, index: number) => {
            return(
            <tr key={index}>
              <td style={{textAlign: 'left'}}>{tableRow.details}</td>
              <td style={{textAlign: 'center'}}>{DisplayDateString(tableRow.time, 'LLL', true, true)}</td>
            </tr>);
          })}
        </tbody>
      </table>
    );
  }
  const [isDisplayProductSelection, setIsDisplayProductSelection] = useState(false);
  return(
  <LoadingContainer isLoading={!isLoaded} size={4}>
    <Grid item md={10} sm={12}>
      <PageHeader>{id !== undefined ? 'Edit Incident' : 'Add Incident'}</PageHeader>
      <Formik
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false} 
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      validate={validateHandler}>
        {(props:any) => (
        <Form>
          <div className='form-container'>
            <div>
              <label htmlFor='title' className='required-text'><b>Title</b></label><br/>
              <TextField id='title' name='title' style={{width: '100%'}} 
              placeholder={props.initialValues.title}
              onChange={props.handleChange}></TextField>
              {props.errors.title ? <div className='error-text'>{props.errors.title}</div> : null}
            </div>

            <div>
              <div className='tool-tip-container'>
                <label htmlFor='incidentStatus' className='required-text'><b>Incident Status</b></label><br/>
                <Icon data-tip data-for='statusTooltip' style={{color:"black"}} name="info-circle" margin="left"/>
                <ReactTooltip id='statusTooltip' effect='solid' place='right'>
                  <ul>
                    <li>Investigating - Incident is currently being investigated.</li>
                    <li>Identified - Incident has been found.</li>
                    <li>Resolved - Incident is resolved and any impact to customers is eliminated or significantly minimized.</li>
                  </ul>
              </ReactTooltip>
              </div>
              <Select id='incidentStatus' name='incidentStatus' options={incidentStatusDisplay}
              placeholder={props.initialValues.incidentStatus ? props.initialValues.incidentStatus.name : 'Select...'}
              onChange={(selectedOption: any) => {
                handleIncidentStatus(selectedOption); 
                return props.setFieldValue('incidentStatus',selectedOption)
              }}>
              </Select>
              {props.errors.incidentStatus ? <div className='error-text'>{props.errors.incidentStatus}</div> : null}
            </div>

            <div className='productsAffected' style={{marginBottom: '5.5px'}}>
              <Button onClick={() => setIsDisplayProductSelection(true)} style={{borderRadius: '5px'}}><Icon name='plus' margin='right' style={{borderRadius: '5px'}}></Icon>Select Products</Button>
              <Modal onHide={() => setIsDisplayProductSelection(false)} show={isDisplayProductSelection} bsSize={'large'} style={{maxHeight: '95vh'}}>
                <Modal.Header closeButton>
                  <Modal.Title>Select Products Affected</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div style={{display: isDisplayProductSelection ? 'flex' : 'none', flexFlow: 'column wrap'}}>
                    <label htmlFor='productsAffected'></label>
                    <div style={{marginLeft: '3.5px'}}>
                      <Icon name={isProductGroupsHidden ? 'plus' : 'minus'} margin='both' onClick={() => handleProductGroupsDisplay()}></Icon>
                      <Checkbox onChange={() => handleAllAffected()} checked={isAllSelected}></Checkbox>
                      Select All
                    </div>
                    <div className='productsDisplay' style={{display: isProductGroupsHidden ? 'none' : 'block'}}>
                      {productsDisplay}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => setIsDisplayProductSelection(false)} style={{borderRadius: '5px'}}><Icon name='check' margin='right'></Icon>Update</Button>
                </Modal.Footer>
              </Modal>
              {props.errors.productsSelected ? <div className='error-text'>{props.errors.productsSelected}</div> : null}
            </div>

            <div style={{display: (incidentStatusInput?.label === IncidentLabelNames.resolvedString ? 'none' : 'block')}}>
              <table className='statusTable' style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td style={{border: 0}}>
                      <label htmlFor='productStatus' className='required-text' style={{margin: 0}}><b>Product Status</b></label><br/>
                    </td>
                    {statusTableDisplay}
                  </tr>
                  {statusCheckboxDisplay}
                </tbody>
              </table>
              {props.errors.productStatus ? <div className='error-text'>{props.errors.productStatus}</div> : null}
            </div>

            <div>
              <label htmlFor='incidentDescription' className='required-text'><b>Incident Description</b></label><br/>
              <Textarea id='incidentDescription' name='incidentDescription'
              placeholder={props.initialValues.incidentDescription}
              onChange={props.handleChange} formControl={true}></Textarea>
              {props.errors.incidentDescription ? <div className='error-text'>{props.errors.incidentDescription}</div> : null}
            </div>

            <div>
              <label htmlFor='detailedIncidentUpdate'><b>Detailed Incident Update</b></label><br/>
              <div className='detailed-incident-content' style={{marginBottom: '10px'}}>
                <TextField id='detailedIncidentUpdate' name='detailedIncidentUpdate'
                onChange={props.handleChange}></TextField>
                <button type='button' id='detailedIncidentUpdateButton'><Icon name='plus' margin='both' onClick={() => {handleDetailedIncidentUpdate(props.values.detailedIncidentUpdate); props.values.detailedIncidentUpdate = null}}></Icon></button>
              </div>
              <div>
                <IncidentTable></IncidentTable>
              </div>
            </div>

            <div className='datetime-container'>
              <div style={{marginRight: '15px'}}>
                <label htmlFor='startTime' className='required-text'><b>Start Date Time</b></label><br/>
                <input type='datetime-local' id='startTime' name='startTime'
                onChange={(selectedOption) => {changeToLocalTime(0); return props.setFieldValue('startTime', selectedOption.target.value)}}></input>
                {props.errors.startTime ? <div className='error-text'>{props.errors.startTime}</div> : null}
              </div>
              <div>
                <label htmlFor='resolveTime' className={incidentStatusInput?.label === IncidentLabelNames.resolvedString ? 'required-text' : ''}><b>Resolved Date Time</b></label><br/>
                <input type='datetime-local' id='resolveTime' name='resolveTime' disabled={incidentStatusInput?.label === IncidentLabelNames.resolvedString ? false : true}
                onChange={(selectedOption) => {changeToLocalTime(1); return props.setFieldValue('resolveTime', selectedOption.target.value)}}></input>
                {props.errors.resolveTime ? <div className='error-text'>{props.errors.resolveTime}</div> : null}
              </div>
            </div>

            <div>
              <div className='tool-tip-container'>
                <label htmlFor='expectedResolveTime'><b>Expected Recovery Date Time</b></label><br/>
                <Icon data-tip data-for='expectedResolveTimeToolTip' style={{color:"black"}} name="info-circle" margin="left"/>
                <ReactTooltip id='expectedResolveTimeToolTip' effect='solid' place='right'>
                  <p>Estimate and Subject to Change.</p>
                </ReactTooltip>
              </div>
              <div>
                <input type='datetime-local' id='expectedResolveTime' name='expectedResolveTime'
                onChange={(selectedOption) => {changeToLocalTime(2); return props.setFieldValue('expectedResolveTime', selectedOption.target.value)}}></input>
                {props.errors.expectedResolveTime ? <div className='error-text'>{props.errors.expectedResolveTime}</div> : null}
              </div> 
            </div>
          </div>

          <div className='form-button-container'>
            <div>
              <Link to='/' style={{marginRight: '5px'}}><Button hcssStyle='ThemeInverted' style={{borderRadius: '5px'}}><Icon name='times' margin='right'></Icon>Cancel</Button></Link>
              <Button disabled={props.isSubmitting} hcssStyle='Theme' type='submit' style={{borderRadius: '5px'}}><Icon name='check' margin='right'></Icon>Submit</Button>
            </div>
          </div>
        </Form>
        )}
        </Formik>
      </Grid>
  </LoadingContainer>
);
}