import axios from 'axios';
import { GetEnvironmentConfig } from "../App.Config";

export const putIncidentAsync = async (incident: any) => {
    let endpoint = GetEnvironmentConfig().endpoints.status_api;
    try{
        await axios.put(`${endpoint}/api/v1/incident`, incident);
    }
    catch(error){
        throw error;
    }
}

export const putUserProducts = async (userProducts: any) => {
    let endpoint = GetEnvironmentConfig().endpoints.status_api;
    try{
        await axios.put(`${endpoint}/api/v1/userproduct`, userProducts);
    }
    catch(error){
        throw error;
    }
}