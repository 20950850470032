import './Dashboard.css';
import React, { useState, useEffect, useRef } from "react";
import { PageHeader, SubTitle, Icon, Link, Button, LoadingContainer } from "hcss-components";
import { ActiveIncident } from "./Components/ActiveIncident";
import { ProductGroup } from "./Components/ProductGroup";
import { Product } from "./Components/Product";
import Grid from '@material-ui/core/Grid';
import { IsUserAdmin, UserDetails } from '../../Auth/UserAuth';
import { useAuth } from 'oidc-react';
import { ReturnIsAllLoaded } from '../../DTO/Constants/Function/ReturnIsAllLoaded';

import { getIncidentsAsync, getProductGroupAsync, getStatusIconsAsync } from '../../API/getAPI';
import { ProductGroupDTO } from '../../DTO/ProductGroupDTO';
import { StatusLabelDTO } from '../../DTO/StatusLabelDTO';
import { IncidentDTO } from '../../DTO/IncidentDTO';
import { ProductDTO } from '../../DTO/ProductDTO';
import { UserDTO } from '../../DTO/UserDTO';

import { GetEnvironmentConfig } from "../../App.Config";
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

export const Dashboard = ():JSX.Element => {
  // isLoaded is set to true whenever all API calls have finished. On state change page is refreshed loading all the new information.
  const isAllLoaded = useRef<boolean[]>([false, false, false]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // Checks for the existence of an active incident. If no activce incident, displays the Good incident's message.
  const [activeIncidentExists, setActiveIncidentExists] = useState<boolean>(false);

  const [activeIncidents, setActiveIncidents] = useState<IncidentDTO[]>([]);
  const [statusIcons, setStatusIcons] = useState<StatusLabelDTO[]>([]);
  const [productGroups, setProductGroups] = useState<ProductGroupDTO[]>([]);

  const [connection, setConnection] = useState<null | HubConnection>(null);

  const LoadDashboardInformation = async (isMounted: boolean) => {
    async function getProductGroups() {
      await getProductGroupAsync().then(resolution => {
        if(isMounted) {
          setProductGroups([...resolution.data]);
        }
      });
      isAllLoaded.current[0] = true;
      setIsLoaded(ReturnIsAllLoaded(isAllLoaded.current));
    }
    getProductGroups();

    async function getStatusIcons() {
      await getStatusIconsAsync().then(resolution => {
        if(isMounted) {
          setStatusIcons([...resolution.data]);
        }
      });
      isAllLoaded.current[1] = true;
      setIsLoaded(ReturnIsAllLoaded(isAllLoaded.current));
    }
    getStatusIcons();

    async function getIncidents() {
      await getIncidentsAsync(true, 'active', null, null).then(resolution => {
        if(isMounted) {
          setActiveIncidents([...resolution.data]);
          setActiveIncidentExists(resolution.data.length ? true : false);
        }
      });
      isAllLoaded.current[2] = true;
      setIsLoaded(ReturnIsAllLoaded(isAllLoaded.current));
    }
    getIncidents();
  }

  useEffect(() => {
    let isMounted = true;

    let endpoint = GetEnvironmentConfig().endpoints.status_api;
    const connect = new HubConnectionBuilder()
      .withUrl(`${endpoint}/hubs/database`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
    LoadDashboardInformation(isMounted);   

    return () => {isMounted = false};
  }, []);

  useEffect(() => {
    let isMounted = true;
    if(connection) {
      connection.start().then(() => {
        connection.on("Update", () => {
          LoadDashboardInformation(isMounted);
        });
      })
      .catch((error) => console.log("Connection Failed: ", error));
    }

    return () => {isMounted = false};
  }, [connection]);

  const NotActiveIncident = ():JSX.Element => {
    return(
      <div className='notActive'>
        <div className='center'>
          <Icon className='fa fa-check-circle fa-lg' name='check-circle' style={{color: '#14AF80'}} margin='both'></Icon>
        </div>
        <div>
          <h2 style={{margin: '10px 0px 5px 5px'}}>All systems are up and running!</h2>
        </div>
      </div>
    );
  }

  const ActiveIncidents = ():JSX.Element => {
    const isAdmin = IsUserAdmin();
    const activeIncidentsDisplay:JSX.Element[] = activeIncidents.filter((incident: IncidentDTO) => {
      if(isAdmin) {
        return true;
      }
      else {
        return !incident.isTestingIncident;
      }
    })
    .map((incident: IncidentDTO, index: number) => {
      return(<ActiveIncident key={index} {...incident}></ActiveIncident>);
    });

    return(
      <div className='active'>
        <SubTitle>Ongoing Incidents</SubTitle>
        <div className='incidents'>
          {activeIncidentsDisplay}
        </div>
      </div>
    );
  }

  const HighestOrderStatus = (products: ProductDTO[]):StatusLabelDTO => {
    let currentHighestOrder : number | undefined = 0;
    let returnIcon: StatusLabelDTO = {
      id: '438A96BA-4FDD-44E7-85BF-A6B82ACBD9C5',
      name: 'Good',
      iconName: 'check-circle',
      iconColor: '#14AF80',
      order: 10,
      isDeleted: false
    };

    for(const product of products) {
      if(product.statusLabel.order) {
        if (product.statusLabel.order > currentHighestOrder) {
          returnIcon = {
            id: product.statusLabel.id,
            name: product.statusLabel.name,
            iconName: product.statusLabel.iconName,
            iconColor: product.statusLabel.iconColor,
            order: product.statusLabel.order,
            isDeleted: product.statusLabel.isDeleted
          };
          currentHighestOrder = product.statusLabel.order;
        }
      }
    }
    return returnIcon;
  }

  const ReturnProductGroupsDisplay = (isAdmin: boolean, productGroups: ProductGroupDTO[]):JSX.Element[] => {
    const productGroupsDisplay:JSX.Element[] = productGroups.filter((productGroup: ProductGroupDTO) => {
      if(isAdmin) {
        return true;
      }
      else {
        return !productGroup.isTestingProductGroup;
      }
    })
    .map((productGroup: ProductGroupDTO, index: number) => {
      const productGroupIcon: StatusLabelDTO = HighestOrderStatus(productGroup.products);
      return(
        <ProductGroup key={index} productGroup={productGroup} statusLabel={productGroupIcon}></ProductGroup>);
    });

    return productGroupsDisplay;
  }
  const productGroupsDisplay:JSX.Element[] = ReturnProductGroupsDisplay(IsUserAdmin(), productGroups);

  const statusIconsDisplay:JSX.Element[] = statusIcons.map((statusIcon: StatusLabelDTO, index: number) => {
    return(
    <div key={index}>
      {statusIcon.name}
      <Icon name={statusIcon.iconName} style={{color: statusIcon.iconColor}} margin='both'></Icon>
    </div>
    );
  });

  const renderAddIncidentButton = () => {
    if (IsUserAdmin()) {
      return(
        <div className='dashboard-button-container'>
          <Link to='/Forms/Incident' style={{marginRight: '5px'}}><Button hcssStyle='Theme' style={{borderRadius: '5px'}}><Icon name='plus' margin='right'></Icon>Add Incident</Button></Link>
        </div>
      );
    }
  }

  return (
    <LoadingContainer isLoading={!isLoaded} size={4}>
      <Grid item md={10} sm={12}>
        <PageHeader>Dashboard</PageHeader>
        {renderAddIncidentButton()}
        <div className='active-container'>
          {activeIncidentExists ? <ActiveIncidents></ActiveIncidents> : <NotActiveIncident></NotActiveIncident>}
        </div>
        <div className='status-container'>
          <div className='icon-container'>
            <SubTitle>Product Groups</SubTitle>
            <div className='content'>
              {statusIconsDisplay}
            </div>
          </div>
          <div className='product-groups-container'>
            {productGroupsDisplay}
          </div>
        </div>
      </Grid>
    </LoadingContainer>
  );
}