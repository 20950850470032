export interface Config {
  endpoints: {
    status_api: string;
  };
  oidc: {
    authority: string;
    clientId: string;
    clientSecret: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
    scope: string;
    responseType: string;
  };
  role: {
    siteAdmin: string;
  };
  telemetry: {
    enabled: boolean,
    appInsightsConnectionString: string,
  };
}

const scopes = "openid profile status:write";
const clientId = "hcss-status-ui";

const devAPI = "https://dev-hcss-status-api.azurewebsites.net";
const devUI = "https://hcssstatusdev.azureedge.net";

const devHccsAppsAPI = "https://dev-status-api.hcssapps.com";
const devHcssAppsUI = "https://dev-status.hcssapps.com";

const prodAPI = "https://prod-hcss-status-api.azurewebsites.net";
const prodUI = "https://hcssstatusprod.azureedge.net";

const prodHcssAppsAPI = "https://status-api.hcssapps.com"; 
const prodHcssAppsUI = "https://status.hcssapps.com";

const prodHcssUI = "https://status.hcss.com";

const devAdminRole = "HCSS ADMIN";
const prodAdminRole = "HCSS ADMIN";

const local: Config = {
  endpoints: {
    status_api: "https://localhost:51274",
  },
  oidc: {
    authority: "https://dev-identity.hcssapps.com",
    clientId: clientId,
    clientSecret: "dummy",
    redirectUri: "https://localhost:3000/oidc/signin-callback",
    postLogoutRedirectUri: "https://localhost:3000/",
    responseType: "code",
    scope: scopes,
  },
  role: {
    siteAdmin: devAdminRole,
  },
  telemetry: {
    enabled: false,
    appInsightsConnectionString: "InstrumentationKey=c2236307-fcd3-44e9-ac28-b1f6ebf1bf50;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=df3a9442-e18d-4bc5-bd7a-1f6a239e53c0",
  },
};

const dev: Config = {
  endpoints: {
    status_api: devAPI,
  },
  oidc: {
    authority: "https://dev-identity.hcssapps.com",
    clientId: clientId,
    clientSecret: "dummy",
    redirectUri: `${devUI}/oidc/signin-callback`,
    postLogoutRedirectUri: `${devUI}/`,
    responseType: "code",
    scope: scopes,
  },
  role: {
    siteAdmin: devAdminRole,
  },
  telemetry: {
    enabled: false,
    appInsightsConnectionString: "InstrumentationKey=c2236307-fcd3-44e9-ac28-b1f6ebf1bf50;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=df3a9442-e18d-4bc5-bd7a-1f6a239e53c0",
  },
};

const devHcssApps: Config = {
  endpoints: {
    status_api: devAPI,
  },
  oidc: {
    authority: "https://dev-identity.hcssapps.com",
    clientId: clientId,
    clientSecret: "dummy",
    redirectUri: `${devHcssAppsUI}/oidc/signin-callback`,
    postLogoutRedirectUri: `${devHcssAppsUI}/`,
    responseType: "code",
    scope: scopes,
  },
  role: {
    siteAdmin: devAdminRole,
  },
  telemetry: {
    enabled: false,
    appInsightsConnectionString: "InstrumentationKey=c2236307-fcd3-44e9-ac28-b1f6ebf1bf50;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=df3a9442-e18d-4bc5-bd7a-1f6a239e53c0",
  },
};

const production: Config = {
  endpoints: {
    status_api: prodAPI,
  },
  oidc: {
    authority: "https://identity.hcssapps.com",
    clientId: clientId,
    clientSecret: "dummy",
    redirectUri: `${prodUI}/oidc/signin-callback`,
    postLogoutRedirectUri: `${prodUI}/`,
    responseType: "code",
    scope: scopes,
  },
  role: {
    siteAdmin: prodAdminRole,
  },
  telemetry: {
    enabled: true,
    appInsightsConnectionString: "InstrumentationKey=bcc8dcd4-2433-49ce-87eb-47caaa30617d;IngestionEndpoint=https://eastus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=ff48b367-99d7-4524-a2eb-06927d77ddf6",
  },
};

const productionHcssApps: Config = {
  endpoints: {
    status_api: prodHcssAppsAPI,
  },
  oidc: {
    authority: "https://identity.hcssapps.com",
    clientId: clientId,
    clientSecret: "dummy",
    redirectUri: `${prodHcssAppsUI}/oidc/signin-callback`,
    postLogoutRedirectUri: `${prodHcssAppsUI}/`,
    responseType: "code",
    scope: scopes,
  },
  role: {
    siteAdmin: prodAdminRole,
  },
  telemetry: {
    enabled: true,
    appInsightsConnectionString: "InstrumentationKey=bcc8dcd4-2433-49ce-87eb-47caaa30617d;IngestionEndpoint=https://eastus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=ff48b367-99d7-4524-a2eb-06927d77ddf6",
  },
};

const productionHcss: Config = {
  endpoints: {
    status_api: prodHcssAppsAPI,
  },
  oidc: {
    authority: "https://identity.hcssapps.com",
    clientId: clientId,
    clientSecret: "dummy",
    redirectUri: `${prodHcssUI}/oidc/signin-callback`,
    postLogoutRedirectUri: `${prodHcssUI}/`,
    responseType: "code",
    scope: scopes,
  },
  role: {
    siteAdmin: prodAdminRole,
  },
  telemetry: {
    enabled: true,
    appInsightsConnectionString: "InstrumentationKey=bcc8dcd4-2433-49ce-87eb-47caaa30617d;IngestionEndpoint=https://eastus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=ff48b367-99d7-4524-a2eb-06927d77ddf6",
  },
};

export const GetEnvironmentConfig = () => {
  const hostname = window.location.hostname.toLowerCase();
  const origin = window.location.origin.toLowerCase();
  if (hostname.includes("localhost")) return local;
  if (hostname.includes("dev-status")) return devHcssApps;
  if (hostname.includes("hcssstatusdev")) return dev;
  if (origin.includes("https://status.hcssapps.com")) return productionHcssApps;
  if (origin.includes("https://dev-status.hcssapps.com")) return devHcssApps;
  if (origin.includes("https://status.hcss.com")) return productionHcss;
  if (hostname.includes("hcssstatusprod")) return production;
  return production;
};
