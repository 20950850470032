import * as React from "react";
import { Layout } from "hcss-components";
import { SideBarLinkItems } from "./Side Bar Link Items/SideBarLinkItems";

/* Creates the layout for the sidebar. Every SideBarLinkItem adds a 'linked'-column */

export const StatusSidebar = ():JSX.Element => {
  return (
    <>
    <Layout.Sidebar>
      <SideBarLinkItems to="/" name='Dashboard' iconName='fa fa-tachometer'/>
      <SideBarLinkItems to="/IncidentHistory" name='Incident History' iconName='fa-list-alt'/>
    </Layout.Sidebar>
    </>
  );
};
export default StatusSidebar;