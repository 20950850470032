import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GetEnvironmentConfig } from "./App.Config";

export const initializeTelemetry = () => {
  const { enabled, appInsightsConnectionString } = GetEnvironmentConfig().telemetry;

  if (!enabled || !appInsightsConnectionString) {
    console.info("Telemetry is disabled or not properly configured.");
    return;
  }

  try {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: appInsightsConnectionString,
        enableAutoRouteTracking: true,
      },
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView();
    console.info("Telemetry initialized successfully.");
  } catch (error) {
    console.error("Failed to initialize telemetry:", error);
  }
};