import './IncidentHistory.css';
import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import Grid from '@material-ui/core/Grid';
import { Modal, Button, PageHeader, SubTitle, Icon, Select, LoadingContainer, notify } from 'hcss-components';
import ReactTooltip from 'react-tooltip';
import { NotActiveIncident } from './Components/NotActiveIncident';
import { getIncidentsAsync } from '../../API/getAPI';
import { IsUserAdmin } from '../../Auth/UserAuth';

import { IncidentDTO } from '../../DTO/IncidentDTO';
import { DisplayDateString } from '../../DTO/Constants/Function/DateConversion';
import { ReturnIsAllLoaded } from '../../DTO/Constants/Function/ReturnIsAllLoaded';

import { GetEnvironmentConfig } from "../../App.Config";
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

const ReturnDateRange = (decrement: number) => {
  const startDate: Date = new Date();
  startDate.setHours(0,0,0,0);
  startDate.setDate(startDate.getDate() - decrement);

  const endDate: Date = new Date();
  endDate.setHours(23,59,59,999);
  
  return {startDate, endDate};
}

const DateRange = {
  Last7Days: {name: "Last 7 Days", decrement: 6},
  Last30Days: {name: "Last 30 Days", decrement: 29},
  AllIncidents: {name: "All", decrement: 0},
  Custom: {name: "Custom", decrement: 0}
};

export const IncidentHistory = ():JSX.Element => {
  // isLoaded is set to true whenever all API calls have finished. On state change page is refreshed loading all the new information.
  const isAllLoaded = useRef<boolean[]>([false]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const selectOptions = new Map<string, {name: string, decrement: number}>();
  for(const [selectOption, value] of Object.entries(DateRange)) {
    selectOptions.set(value.name, {name: value.name, decrement: value.decrement});
  };

  const [incidentsHistory, setIncidentsHistory] = useState<IncidentDTO[]>([]);

  const [incidentHistoryLength, setIncidentHistoryLength] = useState<number>(0);
  // startIndex and endIndex are the respective stard and end indexes for the incidentsHistory list.
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(9);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  // filterPlaceholder is the text that is displayed inside the selection dropdown.
  const [filterPlaceholder, setFilterPlaceholder] = useState<string>(DateRange.AllIncidents.name);
  const [isFilterDisplayed, setIsFilterDisplayed] = useState(false);
  const [isCustomSelected, setIsCustomSelected] = useState<boolean>(false); 

  const [connection, setConnection] = useState<null | HubConnection>(null);

  const LoadIncidentHistory = (isMounted: boolean, startDate: Date | null, endDate: Date | null) => {
    async function getIncidentsHistory() {
      await getIncidentsAsync(true, 'inactive', startDate, endDate).then(resolution => {
        if(isMounted) {
          setIncidentHistoryLength(Math.ceil(resolution.data.length/10)*10);
          setIncidentsHistory([...resolution.data]);
        }
        if(resolution.data.length && (startDate == null && endDate == null)) {
          const tempStartDate = new Date(resolution.data[0].startTime);
          tempStartDate.setHours(0,0,0,0);
            
          const tempEndDate = new Date(resolution.data[resolution.data.length - 1].startTime);
          tempEndDate.setHours(23,59,59,999);

          setStartDate(tempStartDate);
          setEndDate(tempEndDate);
        }
      });
      isAllLoaded.current[0] = true;
      setIsLoaded(ReturnIsAllLoaded(isAllLoaded.current));
    }

    getIncidentsHistory();
  }

  useEffect(() => {
    let isMounted = true;

    let endpoint = GetEnvironmentConfig().endpoints.status_api;
    const connect = new HubConnectionBuilder()
      .withUrl(`${endpoint}/hubs/database`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
    LoadIncidentHistory(isMounted, startDate, endDate);

    return () => {isMounted = false};
  }, []);

  useEffect(() => {
    let isMounted = true;
    if(connection) {
      connection.start().then(() => {
        connection.on("Update", () => {
          LoadIncidentHistory(isMounted, startDate, endDate);
        });
      })
      .catch((error) => console.log("Connection Failed: ", error));
    }

    return () => {isMounted = false};
  }, [connection]);

  // Form's error-checking. If the returned 'errors' object contains a value, the form will not submit.
  const validateHandler = (values: any):Partial<Error> => {
    interface Error {
      [key: string]: any
    }
    const errors: Partial<Error> = {};
    if(isCustomSelected) {
      if(!startDate) {
      errors.startDate = 'Enter a Start Date.'
      }

      if(!endDate) {
        errors.endDate = 'Enter a End Date.'
      }

      if(startDate && endDate) {
        if(startDate > endDate) {
        errors.endDate = 'End Date cannot come before Start Date.'
        }
      }
    }
    
    const missingValues = Object.values(errors);
    if(missingValues.length) {
      let dangerMessage = <>{missingValues.map((errorMessage, index) => <div key={index}>{errorMessage}<br/></div>)}</>;
      notify("danger","Missing fields",dangerMessage,3500);
    }
    return errors;
  }

  // Start and Resolve inputs are sent to the API. Incidents whose Resolved Date Time are in the range are returned.
  const onSubmitHandler = (values: any) => {
    const isAllIncidentsSelected: boolean = (filterPlaceholder === DateRange.AllIncidents.name ? true : false);
    if(isAllIncidentsSelected) {
      LoadIncidentHistory(true, null, null)
    }
    else {
      LoadIncidentHistory(true, startDate, endDate);
    }
  }

  const HandleDateRange = (input: any) => {
    input.persist();

    const inputValue = input.target.value;
    const selectedOption = selectOptions.get(inputValue);
    if(selectedOption) {
      if(selectedOption.name === DateRange.Custom.name) {
        setIsCustomSelected(true);
      }
      else if(selectedOption.name === DateRange.AllIncidents.name) {
        setIsCustomSelected(false);
      }
      else {
        setIsCustomSelected(false);
        const startDate = ReturnDateRange(selectedOption.decrement).startDate;
        const endDate = ReturnDateRange(selectedOption.decrement).endDate;
        setStartDate(startDate);
        setEndDate(endDate);
      }
    }
    setFilterPlaceholder(inputValue);
  }

  interface InitialValuesInterface {
    startDate: Date | null;
    endDate: Date | null;
  }
  const initialValues: InitialValuesInterface = {
    startDate: null,
    endDate: null
  };

  const HandleCustomDate = (inputDate: Date, isStart: boolean) => {
    inputDate.setDate(inputDate.getDate() + 1);
    if(isStart) {
      inputDate.setHours(0,0,0,0);
      setStartDate(inputDate);
    }
    else {
      inputDate.setHours(23,59,59,999);
      setEndDate(inputDate);
    }
  };

  const HandlePreviousIndex = () => {
    setStartIndex(previous => {
      setEndIndex(previous - 1);
      return previous - 10;
    })
  };

  const HandleAfterIndex = () => {
    setEndIndex(previous => {
      setStartIndex(previous + 1);
      return previous + 10;
    })
  };

  const HandlePageSelector = (index: number) => {
    setStartIndex(index);
    setEndIndex(index + 9);
  }

  const incidentsHistoryDate: Map<string, JSX.Element[]> = new Map();
  const isAdmin = IsUserAdmin();
  incidentsHistory.filter((incident: IncidentDTO) => {
    if(isAdmin) {
      return true;
    }
    else {
      return !incident.isTestingIncident;
    }
  })
  .map((incident: IncidentDTO, index: number) => {
    if(index >= startIndex && index <= endIndex)
    {
      const dateString = DisplayDateString(new Date(incident.startTime), 'MMMM YYYY',  true, false);
      const tempIncidentHistoryDate: JSX.Element[] | undefined = incidentsHistoryDate.get(dateString);
      if(tempIncidentHistoryDate !== undefined) {
        incidentsHistoryDate.set(dateString, [...tempIncidentHistoryDate, <NotActiveIncident key={index} {...incident}></NotActiveIncident>]);
      }
      else {
        incidentsHistoryDate.set(dateString, [<NotActiveIncident key={index} {...incident}></NotActiveIncident>]);
      }
    }
  });

  const incidentHistoryDisplay: JSX.Element[] = [];
  incidentsHistoryDate.forEach((value: JSX.Element[], key: string) => {
    incidentHistoryDisplay.push(
      <div key={key} className='container'>
        <div className='date-title'>{key}</div>
        <div className='date-incidents'>{value}</div>
      </div>
    );
  });

  const pageSelectionDisplay: JSX.Element[] = [];
  for(let i=0; i<incidentHistoryLength; i = i+10) {
    pageSelectionDisplay.push(
      <div key={i} className='selection-icon' onClick={() => HandlePageSelector(i)}>{pageSelectionDisplay.length + 1}</div>
    );
  };

  const selectOptionsDisplay: JSX.Element[] = [];
  selectOptions.forEach((option) => {
    selectOptionsDisplay.push(<option key={option.name} value={option.name}>{option.name}</option>)
  });

  return(
    <LoadingContainer isLoading={!isLoaded} size={4}>
      <Grid item md={10} sm={12}>
        <PageHeader>Incident History</PageHeader>
        <div className='incident-button-container'>
          <Button hcssStyle='Theme' onClick={() => setIsFilterDisplayed(true)} style={{borderRadius: '5px'}}><Icon name='filter' margin='right'></Icon>Filter</Button>
        </div>
        <Modal onHide={() => setIsFilterDisplayed(false)} show={isFilterDisplayed} style={{maxHeight: '95vh'}}>
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false} 
              initialValues={initialValues}
              onSubmit={onSubmitHandler}
              validate={validateHandler}>
                {(props:any) => (
                <Form>
                  <div className='incident-history-form'>
                    <div>
                      <label htmlFor='incidentDateRange'></label>
                      <select id='incidentDateRange' name='incidentDateRange' style={{height: '28.5px'}} value={filterPlaceholder}
                      onChange={(selectedOption: any) => HandleDateRange(selectedOption)}>
                      {selectOptionsDisplay}
                      </select>
                    </div>
                    <div className='custom-container'>
                      <label htmlFor='startDate'></label>
                      <input type='date' id='startDate' name='startDate' disabled={!isCustomSelected} value={DisplayDateString(startDate, 'YYYY-MM-DD',  true, false)}
                      onChange={(selectedOption) => HandleCustomDate(new Date(selectedOption.target.value), true)} ></input>
                      {props.errors.startDate ? <div className='error-text'>{props.errors.startDate}</div> : null}
                    
                      <div>&nbsp;-&nbsp;</div>
                      
                      <label htmlFor='endDate'></label>
                      <input type='date' id='endDate' name='endDate' disabled={!isCustomSelected} value={DisplayDateString(endDate, 'YYYY-MM-DD',  true, false)}
                      onChange={(selectedOption) => HandleCustomDate(new Date(selectedOption.target.value), false)}></input>
                      {props.errors.endDate ? <div className='error-text'>{props.errors.endDate}</div> : null}
                    </div>
                  </div>
                  <div className='filter-incident'>
                    <div className='filter-subtext'>Filter is applied against the Start Date and Time of an incident.</div>
                    <Button hcssStyle='Theme' type='submit' onClick={() => setIsFilterDisplayed(false)} style={{borderRadius: '5px'}}><Icon name='check' margin='right'></Icon>Apply</Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <div className='incident-history'>
          {incidentHistoryDisplay}
        </div>
        <div className='page-selector'>
          <div className='number-selector'>
            {pageSelectionDisplay}
          </div>
          <div className='quick-selector'>
            <div style={{display: startIndex === 0 ? 'none' : 'block'}} onClick={() => HandlePreviousIndex()} className='selection-icon'><Icon name='arrow-left' margin='both'></Icon></div>
            <div style={{display: endIndex >= incidentsHistory.length ? 'none' : 'block'}} onClick={() => HandleAfterIndex()} className='selection-icon'><Icon name='arrow-right' margin='both'></Icon> </div>
          </div>
        </div>
      </Grid>
    </LoadingContainer>
  );
}