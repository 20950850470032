import React from "react";
import "./App.css";
import { Layout } from "hcss-components";
import { StatusNavbar } from "./Components/Layout/StatusNavbar";
import { StatusSidebar } from "./Components/Layout/StatusSidebar";
import { StatusSiteRoutes } from "./Routes/StatusSiteRoutes";
import { AuthProvider } from "oidc-react";
import { GetEnvironmentConfig } from "./App.Config";
import { PageFooter } from "./Components/Layout/PageFooter";

/*
Media Query: Collapse the side-bar when the screen-width reaches lower widths.
*/
export const App = (): JSX.Element => {
  return (
    <AuthProvider {...GetEnvironmentConfig()?.oidc} autoSignIn={false} onSignIn={() => {window.history.replaceState(undefined, "home", "/");} } >
      <Layout
      navbar={
        <StatusNavbar />
      }
      sidebar={
        <StatusSidebar />
      }
      mainContent={
        <div className="container-fluid">
          <StatusSiteRoutes />
        </div>
      }
      footer={
        <div className="footer-top-margin">
          <PageFooter />
        </div>
      }
    />
    </AuthProvider>
  );
};

export default App;
