import React, { useState, useEffect } from "react";
import { getVersionNumberAsync } from "../../API/AppVersion/AppVersionAPI";
import { Footer } from "hcss-components";

export const PageFooter = () => {
  const [version, setVersion] = useState<string>("");
  useEffect(() => {
    let isMounted = true;
    async function getVersionNumber() {
      await getVersionNumberAsync().then(resolution => {
        if(isMounted) {
          setVersion(resolution.data);
        }
      });
    }
    getVersionNumber();

    return () => {isMounted = false};
  },[]);
  const versionDisplay = `Status Site - ${version}`;

  return(
    <Footer>{versionDisplay}</Footer>
  );
}