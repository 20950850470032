import './Layout.css';
import React, { useState, useEffect, useRef } from "react";
import { Layout, Modal, Icon, Checkbox, Button, Link } from "hcss-components";
import { HamburgerMenu } from "./HamburgerMenu";
import { IsUserAdmin, UserDetails } from "../../Auth/UserAuth";
import { useAuth } from "oidc-react";

import { ProductGroupDTO } from '../../DTO/ProductGroupDTO';
import { ProductDTO } from '../../DTO/ProductDTO';
import { UserDTO } from '../../DTO/UserDTO';
import { UserProductDTO } from '../../DTO/UserProductDTO';
import { getProductGroupAsync } from '../../API/getAPI';
import { getUserProductsAsync } from '../../API/getAPI';
import { putUserProducts } from '../../API/putAPI';
import { postUserAsync } from '../../API/postAPI';

export const StatusNavbar = (): JSX.Element => {
  const initialLoad = useRef<boolean>(true);

  const [isAsyncLoaded, setIsAsyncLoaded] = useState<boolean>(false);
  const isAllLoaded = useRef<boolean[]>([false]);

  const auth = useAuth();
  const user = UserDetails();
  
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userSubscriptions, setUserSubscriptions] = useState<UserProductDTO | null>(null);

  const [isDisplaySubscriptions, setIsDisplaySubscriptions] = useState<boolean>(false);
  const productSelection = useRef<ProductGroupDTO[]>([]);

  const isUserAdmin: boolean = IsUserAdmin();
  
  interface IsProductSelectedInterface {
    id: string,
    isSelected: boolean
  }
  const [isProductSelected, setIsProductSelected] = useState<IsProductSelectedInterface[][]>([]);
  const [isProductGroupSelected, setIsProductGroupSelected] = useState<boolean[]>([]);

  const [isProductGroupHidden, setIsProductGroupHidden] = useState<boolean[]>([]);

  const profileFirstName = useRef<string>("Not Logged In");
  const profileSurName= useRef<string>("");
  const profileSubText = useRef<string>("");
  const profileDropDownLinkText = useRef<string>("Log in");

  const isUserPosted = useRef<boolean>(false);

  const LoadStatusNavbarInformation = (isMounted: boolean) => {
    async function setProductSubscription() {
      const isProductSelectedMap = new Map<string, string>();
      await getUserProductsAsync(user.userId).then(resolution => {
        if(isMounted) {
          resolution.data.products.map((product: string) => {
            isProductSelectedMap.set(product, product);
          });
        }
      });

      if(initialLoad.current) {
        initialLoad.current = false;
        await getProductGroupAsync().then(resolution => {
          if(isMounted) {
            productSelection.current = resolution.data
            .filter((productGroup: ProductGroupDTO) => {
              if(isUserAdmin) {
                return true;
              }
              else {
                return !productGroup.isTestingProductGroup;
              }
            });
            resolution.data
            .map((productGroup: ProductGroupDTO, firstIndex: number) => {
              let isProductGroupSelectedTemp: boolean = true;
              let isSingleProductSelected: boolean = false;
              const tempProductGroup: IsProductSelectedInterface[] = productGroup.products
              .map((product: ProductDTO, secondIndex: number) => {
                const returnProduct: string | undefined = isProductSelectedMap.get(product.id);
                if(returnProduct) {
                  isSingleProductSelected = true;
                  return {id: product.id, isSelected: true};
                }
                else {
                  isProductGroupSelectedTemp = false;
                  return {id: product.id, isSelected: false};
                }
              });
              setIsProductSelected(previous => {
                return [...previous, tempProductGroup];
              });

              setIsProductGroupSelected(previous => {
                return [...previous, isProductGroupSelectedTemp];
              });

              setIsProductGroupHidden(previous => {
                return [...previous, !isSingleProductSelected];
              });
            });
          }
        });
      }
    }
    setProductSubscription();
  }

  useEffect(() => {
    let isMounted = true;

    if(auth.userData) {
      profileFirstName.current = user.givenName;
      profileSurName.current = user.surName;
      profileSubText.current = user.companyName;
      profileDropDownLinkText.current = "Log Out";
      
      LoadStatusNavbarInformation(isMounted);
      setIsLoggedIn(true);

      const userDTO: UserDTO = {
        id: user.userId,
        name: user.givenName + ' ' + user.surName,
        email: user.email,
        phoneNumber: '',
        isAdmin: isUserAdmin,
        isDeleted: false,
      }

      if(!isUserPosted.current) {
        isUserPosted.current = true;
        const postUser = async () => {
          await postUserAsync(userDTO);
        }
        postUser();
      }
    }
    else {
      profileFirstName.current = "Not Logged In";
      profileSurName.current = "";
      profileSubText.current = "";
      profileDropDownLinkText.current = "Log In";
      
      setIsLoggedIn(false);
    }

    return () => {isMounted = false};
  }, [auth]);

  const HandleProductGroupSelected = (firstIndex: number) => {
    const updateStatus = !isProductGroupSelected[firstIndex];
    setIsProductGroupSelected((previous) => {
      previous[firstIndex] = updateStatus;
      return [...previous];
    });

    setIsProductSelected((previous) => {
      previous[firstIndex] = previous[firstIndex].map((product: IsProductSelectedInterface) => {
        return {id: product.id, isSelected: updateStatus};
      });
      return [...previous];
    })
  }

  const HandleProductSelected = (firstIndex: number, secondIndex: number) => {
    const updateStatus = !isProductSelected[firstIndex][secondIndex].isSelected;
    if(!updateStatus) {
      setIsProductGroupSelected((previous) => {
        previous[firstIndex] = updateStatus;
        return [...previous];
      })
    }

    setIsProductSelected((previous) => {
      previous[firstIndex][secondIndex].isSelected = updateStatus;
      return [...previous];
    });
  }

  const HandleProductGroupCheckbox = (firstIndex: number) => {
    if(firstIndex < isProductGroupSelected.length) {
      return isProductGroupSelected[firstIndex];
    }
    return false;
  }

  const HandleProductCheckbox = (firstIndex: number, secondIndex: number) => {
    if(firstIndex < isProductSelected.length) {
      if(secondIndex < isProductSelected[firstIndex].length) {
        return isProductSelected[firstIndex][secondIndex].isSelected;
      }
    }
    return false;
  }

  const HandleProductGroupHidden = (firstIndex: number) => {
    setIsProductGroupHidden(previous => {
      const updateStatus = !previous[firstIndex];
      previous[firstIndex] = updateStatus;
      return [...previous];
    });
  }

  const productsDisplay: JSX.Element[] = productSelection.current.map((productGroup: ProductGroupDTO, firstIndex: number) => {
    return(
      <div key={`${firstIndex}`}>
        <Icon name={isProductGroupHidden[firstIndex] ? 'plus' : 'minus'} onClick={() => HandleProductGroupHidden(firstIndex)} margin='both'></Icon>
        <Checkbox onChange={() => HandleProductGroupSelected(firstIndex)} checked={HandleProductGroupCheckbox(firstIndex)}></Checkbox>
        {productGroup.name}
        <div style={{marginLeft: '40.5px'}}>
          {productGroup.products.map((product: ProductDTO, secondIndex: number) => {
            return(
              <div key={`${firstIndex}.${secondIndex}`} style={{display: isProductGroupHidden[firstIndex] ? 'none' : 'block'}}>
                <Checkbox onChange={() => HandleProductSelected(firstIndex, secondIndex)} checked={HandleProductCheckbox(firstIndex, secondIndex)}>{product.name}</Checkbox>
              </div>
            );
          })}
        </div>
      </div>
    );
  });

  const DeselectAllProducts = () => {
    isProductSelected.map((productGroup: IsProductSelectedInterface[], firstIndex: number) => {
      setIsProductGroupSelected(previous => {
        previous[firstIndex] = false;
        return [...previous];
      });
      productGroup.map((product: IsProductSelectedInterface, secondIndex: number) => {
        setIsProductSelected(previous => {
          previous[firstIndex][secondIndex].isSelected = false;
          return [...previous];
        });
      })
    })
  }

  const SaveUserProducts = async () => {
    const selectedProducts: string[] = [];
    isProductSelected.map((productGroup: IsProductSelectedInterface[]) => {
      productGroup.map((product: IsProductSelectedInterface) => {
        if(product.isSelected) {
          selectedProducts.push(product.id);
        }
      });
    });

    const userProducts: UserProductDTO = {
      userId: user.userId,
      products: selectedProducts
    }

    setIsDisplaySubscriptions(false);

    await putUserProducts(userProducts);
  }

  return (
    <>
      <Layout.Navbar
        productBrandLight="HCSS"
        productBrandBold="Status Site"
        hamburgerMenu={<HamburgerMenu />}
        helpMenu={<></>}
        profileMenu={
          <Layout.ProfileMenu
            firstName={profileFirstName.current}
            lastName={profileSurName.current}
            subtext={profileSubText.current}
          >
            <Layout.DropdownDivider />
            <Layout.DropdownMenuItem onClick={() => setIsDisplaySubscriptions(true)} iconClassName="fa fa-envelope" style={{display: isLoggedIn ? 'block' : 'none'}}>
              {"Subscribe"}
            </Layout.DropdownMenuItem>
            <Layout.DropdownMenuItem onClick={isLoggedIn ? auth.signOutRedirect: auth.signIn} iconClassName="fa fa-power-off">
              {profileDropDownLinkText.current}
            </Layout.DropdownMenuItem>
          </Layout.ProfileMenu>
        }
      />
      <Modal className='subscription-modal' onHide={() => setIsDisplaySubscriptions(false)} show={isDisplaySubscriptions} bsSize={'large'} style={{maxHeight: '95vh'}}>
        <Modal.Header className='header' style={{backgroundColor: 'rgb(235,235,235)', margin: '0'}} closeButton>
          <Modal.Title style={{color: 'rgb(0,0,0)', fontSize: '26px'}}><b>Subscriptions</b></Modal.Title>
          <div style={{color: 'rgb(0,0,0)', fontSize: '16px'}}>Sign up for the products you want to receive notifications for.</div>
          <div className='save'>
            <Button hcssStyle='Theme' onClick={() => SaveUserProducts()} style={{borderRadius: '5px'}}><Icon name='check' margin='right'></Icon>Save</Button>
            <Link style={{marginLeft: '22.5px', color: 'rgb(245,25,25)', fontSize: '16px'}} onClick={() => DeselectAllProducts()}>Deselect All Subscriptions</Link>
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className='products-display'>
          {productsDisplay}
        </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default StatusNavbar;
