import { Layout } from "hcss-components";
import React, { useEffect } from "react";

enum ENVIRONMENTS {
    DEV = "dev",
    LOCALHOST = "localhost",
    STAGING = "staging"
}

const GetAppUrls = () => {
    let currUrl: string = window.location.href.toLowerCase();
    if (currUrl.includes(ENVIRONMENTS.DEV) || currUrl.includes(ENVIRONMENTS.LOCALHOST)) {
        return {
            credentials: "https://dev-credentials.hcssapps.com",
            heavyJob: "https://dev-heavyjob.hcssapps.com/",
            skills: "https://dev-skills.hcssapps.com",
            forms: "https://dev-forms.hcssapps.com",
            telematics: "https://gps-r-dev.hcssapps.com/",
            eLogs: "https://elogs.hcss.com/",
            trucking: "https://dev1-trucking.hcssapps.com/",
            intelligence: "https://dev-BI.hcssapps.com/",
            plans: "https://dev.plans.hcssapps.com/",
            community: "https://full-hcss.cs78.force.com/Community",
            academy: "https://academy.hcss.com/"
        }
    }
    else if (currUrl.includes(ENVIRONMENTS.STAGING)) {
        return {
            credentials: "https://staging-credentials.hcssapps.com",
            heavyJob: "https://staging-heavyjob.hcssapps.com",
            skills: "https://staging-skills.hcssapps.com",
            forms: "https://staging-forms.hcssapps.com",
            telematics: "https://gps-r-staging.hcssapps.com/",
            eLogs: "https://elogs.hcss.com/",
            trucking: "https://staging-trucking.hcssapps.com/",
            intelligence: "https://staging-BI.hcssapps.com/",
            plans: "https://plans.hcssapps.com", // Plans does not have a staging environment
            community: "https://full-hcss.cs78.force.com/Community",
            academy: "https://academy.hcss.com/"
        }
    }
    else
        return {
            credentials: "https://credentials.hcssapps.com",
            heavyJob: "https://dev-heavyjob.hcssapps.com/",
            skills: "https://skills.hcssapps.com",
            forms: "https://forms.hcssapps.com",
            telematics: "https://telematics.hcssapps.com",
            eLogs: "https://elogs.hcss.com/",
            trucking: "https://trucking.hcssapps.com/",
            intelligence: "https://bi.hcssapps.com/",
            plans: "https://plans.hcssapps.com",
            community: "https://community.hcss.com/",
            academy: "https://academy.hcss.com/"
        }
}

const appObject = {
    credentials: {
        productBrandLight: "HCSS", productBrandBold: "Credentials", iconName: "product-credentials", color: "#5f7361", subtitle: "Licenses & user subscriptions"
    },
    supportLinks: {
        productBrandLight: "HCSS", productBrandBold: "Support Links", iconName: "external-link", color: "#5f7361", subtitle: "Internal and External Support Links"
    },
    heavyJob: {
        productBrandLight: "HCSS", productBrandBold: "HeavyJob", iconName: "product-heavyjob", color: "#005eb8", subtitle: "Time cards & job analysis"
    },
    skills: {
        productBrandLight: "HCSS", productBrandBold: "Skills", iconName: "product-skills", color: "#50748a", subtitle: "Employee skills & certifications"
    },
    forms: {
        productBrandLight: "HCSS", productBrandBold: "Forms", iconName: "product-forms", color: "#3e8ede", subtitle: "Custom forms & fillable PDFs"
    },
    telematics: {
        productBrandLight: "HCSS", productBrandBold: "Telematics", iconName: "product-gps", color: "#00b5e2", subtitle: "Fleet tracking & analysis"
    },
    eLogs: {
        productBrandLight: "HCSS", productBrandBold: "eLogs", iconName: "product-elogs", color: "#79993d", subtitle: "Electronic logging devices"
    },
    trucking: {
        productBrandLight: "HCSS", productBrandBold: "Trucking", iconName: "product-trucking", color: "#00afab", subtitle: "Truck & ticket management"
    },
    intelligence: {
        productBrandLight: "HCSS", productBrandBold: "Intelligence", iconName: "product-hcss-intelligence", color: "#1a3e70", subtitle: "Business intelligence & data warehousing"
    },
    plans: {
        productBrandLight: "HCSS", productBrandBold: "Plans", iconName: "hcss-plans-icon", color: "#4d5fab", subtitle: "Digital plan management"
    },
    community: {
        productBrandLight: "HCSS", productBrandBold: "Community", iconName: "hcss-community", color: "#0f9948", subtitle: "Support resources & peer discussions"
    },
    academy: {
        productBrandLight: "HCSS", productBrandBold: "Academy", iconName: "product-academy", color: "#009639", subtitle: "Online training courses"
    }
}

export const HamburgerMenu = () => {

    useEffect(() => {
        let elements = document.getElementsByTagName(".hamburgermenu-item a");
        for (let index = 0; index < elements.length; index++) {
            elements[index].setAttribute("target", "_blank");
        }
    }, [])

    const urls = GetAppUrls();

    let credentials = <Layout.HamburgerMenuItem {...appObject.credentials} href={urls.credentials} />
    let heavyJob = <Layout.HamburgerMenuItem {...appObject.heavyJob} href={urls.heavyJob} />
    let skills = <Layout.HamburgerMenuItem {...appObject.skills} href={urls.skills} />
    let forms = <Layout.HamburgerMenuItem {...appObject.forms} href={urls.forms} />
    let telematics = <Layout.HamburgerMenuItem {...appObject.telematics} href={urls.telematics} />
    let eLogs = <Layout.HamburgerMenuItem {...appObject.eLogs} href={urls.eLogs} />
    let trucking = <Layout.HamburgerMenuItem {...appObject.trucking} href={urls.trucking} />
    let intelligence = <Layout.HamburgerMenuItem {...appObject.intelligence} href={urls.intelligence} />
    let plans = <Layout.HamburgerMenuItem {...appObject.plans} href={urls.plans} />
    let community = <Layout.HamburgerMenuItem {...appObject.community} href={urls.community} />
    let academy = <Layout.HamburgerMenuItem {...appObject.academy} href={urls.academy} />
    let supportLinks = <Layout.HamburgerMenuItem {...appObject.supportLinks} href={"/SupportLinks"} />

    return (
        <Layout.HamburgerMenu>
            {supportLinks}
            {credentials}
            {heavyJob}
            {skills}
            {forms}
            {telematics}
            {eLogs}
            {trucking}
            {intelligence}
            {plans}
            {community}
            {academy}
        </Layout.HamburgerMenu>
    );

}