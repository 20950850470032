import "../Dashboard.css"
import React from "react";
import { Icon } from "hcss-components";

import { ProductDTO } from '../../../DTO/ProductDTO';

export const Product = (props: ProductDTO):JSX.Element => {
  return(
    <div data-testid='product' className='product-container' style={{padding: (props.description === null ? '18.25px 12.5px 18.25px 37px' : '12.5px 12.5px 12.5px 37px')}}>
      <Icon data-testid='icon' name={props.statusLabel.iconName} style={{color: props.statusLabel.iconColor, fontSize: '17.5px'}} margin='both'></Icon>
      <div className='content'>
        <div data-testid='name'style={{fontSize: '15px', marginLeft: '10px'}}>{props.name}</div>
        <div data-testid='description'style={{fontSize: '12px', color: 'rgb(121,121,121)', marginLeft: '10px'}}>{props.description}</div>
      </div>
    </div>
  );
}