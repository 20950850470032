import axios from 'axios';
import { GetEnvironmentConfig } from "../App.Config";

export const getProductGroupAsync = async ():Promise<any> => {
    let endpoint = GetEnvironmentConfig().endpoints.status_api;
    let request = await axios.get(`${endpoint}/api/v1/productgroup`);
    return request;
}

export const getIncidentStatusAsync = async ():Promise<any> => {
    let endpoint = GetEnvironmentConfig().endpoints.status_api;
    let request = await axios.get(`${endpoint}/api/v1/incidentstatus`);
    return request;
}

export const getStatusIconsAsync = async ():Promise<any> => {
    let endpoint = GetEnvironmentConfig().endpoints.status_api;
    let request = await axios.get(`${endpoint}/api/v1/statuslabel`);
    return request;
}

export const getIncidentAsync = async (id: string):Promise<any> => {
    let endpoint = GetEnvironmentConfig().endpoints.status_api;
    let request = await axios.get(`${endpoint}/api/v1/incident/${id}`);
    return request;
}

export const getIncidentsAsync = async (isDescending: boolean=true, filter: string='All', startDate: Date | null, endDate: Date | null):Promise<any>  => {
    let endpoint = GetEnvironmentConfig().endpoints.status_api;
    let request = await axios.get(`${endpoint}/api/v1/incident/filter`, {params: {isDescending: isDescending, filter: filter, startDate: startDate, endDate: endDate}});
    return request;
}

export const getUserProductsAsync = async (id: string):Promise<any> => {
    let endpoint = GetEnvironmentConfig().endpoints.status_api;
    let request = await axios.get(`${endpoint}/api/v1/userproduct/${id}`);
    return request;
}