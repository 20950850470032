import React from "react";
import { Switch, Route } from "react-router-dom";
import { Dashboard } from "../Pages/Dashboard/Dashboard";
import { IncidentForm } from "../Pages/Dashboard/Forms/IncidentForm";
import { IncidentHistory } from "../Pages/IncidentHistory/IncidentHistory";
import { PrivateRoute } from "../Components/Common/private-route";

/* Routes the React browser. Switch is similar to a Switch-case and 'exact path' checks if the 
current path matches the <Route></Route>'s path, if so loads the component.
*/

export const StatusSiteRoutes = () => {
    return (
      <>
        <Switch>
          <Route exact path="/IncidentHistory" component={IncidentHistory} />
          <PrivateRoute exact path="/Forms/Incident" component={IncidentForm} />
          <PrivateRoute exact path="/Forms/Incident/:id" component={IncidentForm} />
          <Route path="/" component={Dashboard} />
        </Switch>
      </>
    )
};