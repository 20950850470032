import * as React from "react";
import { Layout } from "hcss-components";

/* SideBarLinkItems are the individual links beneath the HCSS Status Site e.g 'Dashboard | Incident History' */

interface SideBarLinkItemsInterface {
  to: string,
  name: string,
  iconName: string
}
export const SideBarLinkItems = (props: SideBarLinkItemsInterface):JSX.Element => {
  return (
    <Layout.SidebarLinkMenuItem renderHtmlAnchor to={props.to} iconClassName={props.iconName}>
      {props.name}
    </Layout.SidebarLinkMenuItem>
  );
}