import axios from 'axios';
import { GetEnvironmentConfig } from "../App.Config";

export const postIncidentAsync = async (incident: any) => {
    let endpoint = GetEnvironmentConfig().endpoints.status_api;
    try{
        await axios.post(`${endpoint}/api/v1/incident`, incident);
    }
    catch(error){
        throw error;
    }
}

export const postUserAsync = async (user: any) => {
    let endpoint = GetEnvironmentConfig().endpoints.status_api;
    try{
        await axios.post(`${endpoint}/api/v1/user`, user);
    }
    catch(error){
        throw error;
    }
}