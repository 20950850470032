import { useAuth } from "oidc-react";
import { GetEnvironmentConfig } from "../App.Config";

export function UserDetails() {
  const auth = useAuth();

  const profile = auth.userData ? auth.userData.profile : null;
  const companyClaim =
    "http://credentials.hcssapps.com/schemas/2015/09/identity/claims/companyname";
  const givenNameClaim =
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname";
  const surNameClaim =
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname";
  const emailClaim =
    "http://credentials.hcssapps.com/schemas/2015/09/identity/claims/emailcontact";
  const roleClaim =
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
  const userIdClaim =
    "http://credentials.hcssapps.com/schemas/2015/09/identity/claims/userid";
  const companyName = profile ? profile[companyClaim] : "";
  const givenName = profile ? profile[givenNameClaim] : "";
  const surName = profile ? profile[surNameClaim] : "";
  const email = profile ? profile[emailClaim] : "";
  const role = profile ? profile[roleClaim] : "";
  const userId = profile ? profile[userIdClaim] : "";
  return { givenName, surName, email, companyName, role, userId };
}

// Checks if UserDetails.role === adminRole specified inside App.config.tsx. If role is not admin, then add/edit buttons for incidents are not displayed.
export function IsUserAdmin():boolean {
  const userDetails = UserDetails();
  const role = userDetails.role.toUpperCase();

  return (role === GetEnvironmentConfig().role.siteAdmin);
}
