import React from 'react';
import { Redirect, Route, RouteProps } from "react-router";
import { IsUserAdmin } from "../../Auth/UserAuth";
import { useAuth } from "oidc-react";

export function PrivateRoute(props: RouteProps) {

  const auth = useAuth();

  if (!auth.userData) {
    auth.signIn();
    return <div />;
  }
  if (!IsUserAdmin()) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
}
