import "../Dashboard.css"
import React, { useState } from "react";
import { Product } from "./Product";
import { Icon } from "hcss-components";

import { ProductGroupDTO } from '../../../DTO/ProductGroupDTO';
import { ProductDTO } from '../../../DTO/ProductDTO';
import { StatusLabelDTO } from '../../../DTO/StatusLabelDTO';

interface ProductGroupInterface {
  productGroup: ProductGroupDTO,
  statusLabel: StatusLabelDTO
}
export const ProductGroup = (props: ProductGroupInterface):JSX.Element => {
  const [isHidden, setIsHidden] = useState(true);
  const handleClick = () => {
    setIsHidden(previous => !previous);
  };

  return(
    <div className='productGroup'>
      <button data-testid='button' type='button' className='product-button full-width' onClick={handleClick}>
        <div className='header'>
          <Icon data-testid='icon' className='fa-lg' name={props.statusLabel.iconName} style={{color: props.statusLabel.iconColor, fontSize: '17.5px'}} margin='both'></Icon>
          <div data-testid='name' style={{fontSize: '16px', marginLeft: '7px'}}>{props.productGroup.name}</div>
        </div>
        <Icon name={isHidden ? 'chevron-right': 'chevron-down'} style={{color: 'rgb(0,0,0)'}} margin='both'></Icon>
      </button>
      <div data-testid='content' className='product-content full-width' style={{display: isHidden ? 'none' : 'block'}}>
        {props.productGroup.products.map((product: ProductDTO, index: number) => {
          return(
            <Product key={index} {...product}></Product>
          );
        })}
      </div>
    </div>
  );
}